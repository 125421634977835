import ReactDOM from 'react-dom/client';
import {
  BrowserRouter as Router,
  redirect,
  Route,
  Routes,
  Outlet,
} from 'react-router-dom';
import ConfiguredChakraProvider from '../../config/chakra';
import { initTagManager } from '../../config/googleTagManager';
import { ConfiguredIntlProvider } from '../../config/intl';
import { initRaygun } from '../../config/raygun';
import { ConfiguredSWRWrapper } from '../../config/swr';
import PublicView from '../../pages/public-view';
import { PublicViewProvider } from '../../pages/public-view/usePublicView';
import '../../styles/fonts.scss';
import { AnalyticsPageViewTracker } from '../../hooks/analytics/useAnalytics';

function RedirectToMarketingHome() {
  redirect('https://mooven.com');
  return null;
}

export function PublicViewRoutes() {
  return (
    <Routes>
      <Route
        element={
          <ConfiguredSWRWrapper>
            <Outlet />
          </ConfiguredSWRWrapper>
        }
      >
        <Route
          path="/v/:linkId"
          element={
            <PublicViewProvider>
              <PublicView />
            </PublicViewProvider>
          }
        />
        <Route path="*" element={<RedirectToMarketingHome />} />
      </Route>
    </Routes>
  );
}

function PublicViewRoot() {
  return (
    <ConfiguredIntlProvider>
      <ConfiguredChakraProvider>
        <Router>
          <AnalyticsPageViewTracker />
          <PublicViewRoutes />
        </Router>
      </ConfiguredChakraProvider>
    </ConfiguredIntlProvider>
  );
}

function init() {
  const rootNode = document.getElementById('root');
  if (rootNode) {
    initRaygun();
    initTagManager();
    ReactDOM.createRoot(rootNode).render(<PublicViewRoot />);
  }
}

init();
