import { Box } from '@chakra-ui/react';
import { Polyline } from '@react-google-maps/api';
import { useState } from 'react';
import { POLYLINE_ICONS } from 'design-system/settings/google-maps';
import BaseMap from '../../components/BaseMap';
import useMapBounds from '../../hooks/useMapBounds';
import { colorsSortedByKey, parseWkt } from '../../utils/routes';
import { usePublicView } from './usePublicView';

export default function PublicMap() {
  const { routes } = usePublicView();
  const [mapInstance, setMapInstance] = useState<google.maps.Map>();
  const onCountMapLoad = (map: google.maps.Map) => {
    setMapInstance(map);
  };
  const selectedRoutes = routes.filter(({ selected }) => selected);
  useMapBounds({
    mapInstance,
    routes,
  });
  const colors = colorsSortedByKey(routes, 'routeId');

  return (
    <Box
      position="relative"
      height="100%"
      width="100%"
      borderRadius="2xl"
      overflow="hidden"
    >
      <BaseMap onLoad={onCountMapLoad}>
        {selectedRoutes.map((route) => (
          <Polyline
            key={route.routeId}
            options={{
              strokeColor: colors[route.routeId],
              strokeWeight: 6,
              icons: POLYLINE_ICONS,
              strokeOpacity: 0.6,
            }}
            path={parseWkt(route.wkt)}
          />
        ))}
      </BaseMap>
    </Box>
  );
}
