import { backOff } from 'exponential-backoff';
import { ServerError } from '../types/error';
import { Maybe } from '../types/utils';
import { errorReport } from './errors';

const SUPPORT_CSV_TYPE = ['application/csv', 'text/csv; charset=utf-8'];

export class ServerErrorImpl extends Error {
  public code: number;

  public details: Record<string, any>;

  constructor(code: number, message: string, details: Record<string, any>) {
    super(message);
    this.code = code;
    this.details = details;
    Object.setPrototypeOf(this, ServerErrorImpl.prototype);
  }
}

export async function unwrapResponse<T>(
  response: Maybe<Response>,
  details: Record<string, any> = {}
): Promise<T> {
  // This is used to handle csv blob response
  if (response && response.headers) {
    const contentType = response.headers.get('content-type') ?? '';
    if (SUPPORT_CSV_TYPE.includes(contentType)) {
      return response as any;
    }
  }

  // This is used to unwrap non-standard responses (i.e. numbers)
  if (!response || !response.json) {
    return response as any;
  }

  let unwrapped: any;
  try {
    unwrapped = await response.json();
  } catch (e) {
    if (!response.ok) {
      // Empty non-OK response
      const error = new ServerErrorImpl(
        response.status ?? 500,
        response.status?.toString() || 'Empty status response',
        details
      );
      throw error;
    } else {
      // If response is OK, these are successful, non-JSON responses
      return null as unknown as T;
    }
  }

  if (!response.ok) {
    if (unwrapped.code && unwrapped.message) {
      // Does it look like a ServerError?
      const error = new ServerErrorImpl(
        unwrapped.code,
        unwrapped.message,
        details
      );
      throw error;
    } else if (unwrapped.status) {
      const error = new ServerErrorImpl(
        unwrapped.status,
        unwrapped.error || response.statusText || 'Unknown error type',
        details
      );
      throw error;
    } else {
      // Unknown non-OK responses
      const error = new ServerErrorImpl(
        response.status ?? 500,
        response.statusText || 'Unknown error type',
        details
      );
      throw error;
    }
  }
  return unwrapped as T;
}

export async function standardFetch<T = null>(
  fetchUrl: string,
  fetchParams?: RequestInit
): Promise<T> {
  try {
    const response = await backOff(
      async () => {
        const fetchResponse = await fetch(fetchUrl, {
          ...fetchParams,
          credentials: 'include',
        });
        if (fetchResponse.status === 401) {
          // We should not read json to avoid race condition
          // as we also are readin jsonbody from CheckAuth.tsx
          throw new ServerErrorImpl(401, 'Unauthorized', {
            fetchUrl,
            fetchParams,
          });
        }
        const unwrapped = await unwrapResponse<T>(fetchResponse, {
          fetchUrl,
          fetchParams,
        });
        return unwrapped;
      },
      {
        numOfAttempts: 3,
        startingDelay: Math.floor(Math.random() * 1000),
        delayFirstAttempt: false,
        retry: (e: any) => (e.code ?? 500) >= 500,
      }
    );
    return response;
  } catch (e: any) {
    if ((e.code ?? 500) < 500) {
      if (e.code !== 401 && !fetchUrl.includes('/panels/')) {
        errorReport.log(e, {
          fetchUrl,
          fetchParams,
          details: e.details ?? '',
        });
      }
    } else {
      errorReport.critical(e, {
        fetchUrl,
        fetchParams,
        details: e.details ?? '',
      });
    }
    throw e;
  }
}

export async function fetchWithError<T>(
  fetchUrl: string,
  fetchParams?: RequestInit
): Promise<T> {
  const headers = new Headers(fetchParams?.headers);
  headers.append('content-type', 'application/json');
  try {
    const response = await backOff(
      async () => {
        const fetchResponse = await fetch(fetchUrl, {
          ...fetchParams,
          headers,
          credentials: 'include',
        });
        if (fetchResponse.status === 401) {
          // We should not read json to avoid race condition
          // as we also are readin jsonbody from CheckAuth.tsx
          throw new ServerErrorImpl(401, 'Unauthorized', {
            fetchUrl,
            fetchParams,
          });
        }
        let unwrapped: T;
        try {
          unwrapped = await fetchResponse.json();
        } catch (e) {
          if (fetchResponse.ok) {
            return null as unknown as T;
          }
          throw new ServerErrorImpl(
            fetchResponse.status,
            fetchResponse.statusText || 'Unknown error type',
            { fetchUrl, fetchParams }
          );
        }
        if (!fetchResponse.ok) {
          if ((unwrapped as any).code) {
            const err = unwrapped as unknown as ServerError;
            const error = new ServerErrorImpl(err.code, err.message, {
              fetchUrl,
              fetchParams,
            });
            throw error;
          }
          throw new ServerErrorImpl(
            fetchResponse.status,
            'Unknown error type',
            {
              fetchUrl,
              fetchParams,
            }
          );
        }
        return unwrapped;
      },
      {
        numOfAttempts: 3,
        startingDelay: Math.floor(Math.random() * 1000),
        delayFirstAttempt: false,
        retry: (e: any) => (e.code ?? 500) >= 500,
      }
    );
    return response;
  } catch (e: any) {
    if ((e.code ?? 500) < 500) {
      if (e.code !== 401) {
        errorReport.log(e);
      }
    } else {
      errorReport.critical(e);
    }
    throw e;
  }
}
